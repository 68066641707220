$primary: #81eb81;
$text: #333;
$background: #FFFFFF;
$backgroundSecondary: #EEEEEE;

html,
body {
    margin: 0px;
    padding: 0px;
}

body {
    min-width: 650px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: $text;
    background-color: $background;
}

h1 {
    font-size: 175%;
}

h2 {
    font-size: 150% !important; // bootstrap css :(
    margin-top: 32px !important; // bootstrap css :(
}

.mdi {
    padding-right: 12px;
}

.in-progress {
    padding: 32px;
    text-align: center;
    font-weight: bold;
}

.info {
    padding: 32px;
    font-weight: bold;
    text-align: center;
}

// ----------- Div -----------
.flex {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.grow-1 {
    flex-basis: 100px;
    flex-grow: 1;
}

.grow-2 {
    flex-basis: 100px;
    flex-grow: 2;
}

.grow-3 {
    flex-basis: 100px;
    flex-grow: 3;
}

// ----------- Layout -----------
.layout {
    min-height: 100vh;
    align-items: stretch;

    header {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        // background-color: $primary;
        //background: linear-gradient(90deg, rgba(0, 212, 255, 1) 0%, rgba(2, 47, 119, 1) 100%);
        background: rgb(178, 238, 250);
        background: linear-gradient(90deg, rgba(178, 238, 250, 1) 0%, rgba(79, 114, 170, 1) 50%, rgba(44, 212, 246, 1) 100%);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);

        a.logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $text;
            font-size: 32px;
            font-weight: bold;
            text-decoration: none;

            img {
                height: 42px;
                margin-right: 16px;
            }
        }
    }

    nav {
        display: flex;

        a {
            display: block;
            color: $text;
            font-size: 120%;
            font-weight: bold;
            text-decoration: none;
            margin: 8px 16px;
        }
    }

    .main-content {
        padding: 32px;
        width: 100%;
        max-width: 1325px;
        margin: 0px auto;
    }

    footer {
        font-size: 85%;
        padding: 8px 16px;
        background-color: $backgroundSecondary;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .rights {
            text-align: right;
        }
    }
}

.gallery {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    .item {
        margin: 8px;
        cursor: pointer;

        &:hover {
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
        }
    }

    .gallery-show {
        position: fixed;
        z-index: 100;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);

        display: flex;
        flex-direction: row;
        align-items: stretch;

        .close {
            position: absolute;
            z-index: 2;
            top: 0px;
            right: 0px;
            padding: 16px;

            cursor: pointer;
            color: white;
            font-size: 200%;
        }

        .arrow {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            cursor: pointer;

            &:hover {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }

        .gallery-big {
            flex-grow: 1;
            flex-basis: 300px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

// ----------- Carousel-----------
.carousel {
    .carousel-inner {
        height: 666px;
        background-color: #DDDDDD;

        display: flex;
        align-items: center;
    }
}